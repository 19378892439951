// Dependencies
// import moment from 'moment';
import moment from 'moment-timezone';

// Constants
import { CONDITION_TYPES } from 'Constants';

// export const isLocationNudgeExpired = (conditions) => {
//   if (conditions) {
//     const { dateTimeRangeEnd } = conditions;
//     console.log(dateTimeRangeEnd)
//     return dateTimeRangeEnd == null ? false : moment().isAfter(dateTimeRangeEnd);
//   }
//   return false;
// };

export const isLocationNudgeExpired = (conditions) => {
  if (conditions) {
    const { dateTimeRangeEnd } = conditions;

    // Ensure dateTimeRangeEnd is not null or undefined
    if (dateTimeRangeEnd == null) {
      return false;
    }

    // Compare only the date (ignoring time) by setting both to the start of the day
    const endDate = moment(dateTimeRangeEnd).startOf('day');
    const currentDate = moment().startOf('day');

    return currentDate.isAfter(endDate); // Check if the current date is after the end date
  }

  return false;
};

export const isTimeBasedNudgeExpired = (conditions) => {
  let dateTime = null;
  if (conditions) {
    conditions.forEach((condition) => {
      if (condition.conditionType.id === CONDITION_TYPES.SCHEDULED_ACTION.ID) {
        dateTime = condition.dateTime;
      }
    });
    return moment().isAfter(dateTime);
  }
  return false;
};

// export const getTime = (dateTime, timeHourValue, timeMinuteValue, isWithIncrement = false) => {
//   /*
//  * This function takes the following parameters:
//  * dateTime: dateTime object
//  * timeHourValue: string that can be cast to number
//  * timeMinuteValue: string that can be cast to number
//  * isWithIncrement: string that can be cast to number
//  *
//  * It returns a formatted dateTime using the given params
//  */
//   if (isWithIncrement) {
//     return moment(dateTime)
//       .set('hour', Number(timeHourValue) + 12)
//       .set('minute', Number(timeMinuteValue));
//   }
//   return moment(dateTime)
//     .set('hour', Number(timeHourValue))
//     .set('minute', Number(timeMinuteValue));
// };


export const getTime = (dateTime, timeHourValue, timeMinuteValue, isPM) => {
  let hour = Number(timeHourValue);
  let minute = Number(timeMinuteValue);

  if (isPM && hour !== 12) {
    hour += 12; // Convert 1-11 PM to 13-23
  } else if (!isPM && hour === 12) {
    hour = 0; // Convert 12 AM to 00:00
  }

  const newDateTime = moment(dateTime)
    .set('hour', hour)
    .set('minute', minute);

  console.log('getTime - Final Computed Time:', newDateTime.format());
  return newDateTime;
};


//
// export const determineTimeChange = (oldTime, newTime, dateTime, timeHourValue, timeMinuteValue) => {
//   /*
//  * This function takes the following parameters:
//  * oldTime: dateTime object
//  * newTime: dateTime object
//  * dateTime: dateTime object
//  * timeHourValue: string that can be cast to number
//  * timeMinuteValue: string that can be cast to number
//  *
//  * It returns a formatted dateTime using the given params
//  */
//   let result = null;
//   if ((oldTime !== newTime) && (newTime.includes('PM'))) {
//     // account for updating from AM to PM
//     result = getTime(dateTime, timeHourValue, timeMinuteValue, true);
//   } else if (oldTime !== newTime) {
//     // if time changed in same zone (AM or PM)
//     result = getTime(dateTime, timeHourValue, timeMinuteValue);
//     // time unchanged in PM
//   } else if ((oldTime == newTime) && newTime.includes('PM')) {
//     result = getTime(dateTime, timeHourValue, timeMinuteValue, true);
//   } else if (newTime) {
//     result = getTime(dateTime, timeHourValue, timeMinuteValue);
//   }
//   return result;
// };
//
// export const getDateTimeObject = (timeTypeValue, dateTime, timeHourValue, timeMinuteValue) => {
//   /*
//  * This function takes the following parameters:
//  * timeTypeValue: string
//  * dateTime: dateTime object
//  * timeHourValue: string that can be cast to number
//  * timeMinuteValue: string that can be cast to number
//  *
//  * It returns a formatted dateTime using the given params
//  */
//   let result = null;
//   if (timeTypeValue === 'P') {
//     result = getTime(dateTime, timeHourValue, timeMinuteValue, true);
//   } else {
//     result = getTime(dateTime, timeHourValue, timeMinuteValue);
//   }
//   return result;
// };
//
// export const getDateTimeObjectWithUpdate = (
//   isUpdate, oldTime, timeTypeLabel,
//   timeTypeValue, dateTime, timeHourValue, timeMinuteValue,
// ) => {
//   /*
//  * This function takes the following parameters:
//  * isUpdate: boolean
//  * oldTime: dateTime object
//  * timeTypeLabel: string
//  * timeTypeValue: string
//  * dateTime: dateTime object
//  * timeHourValue: string that can be cast to number
//  * timeMinuteValue: string that can be cast to number
//  *
//  * It returns a formatted dateTime using the given params
//  */
//   let result = dateTime;
//   if (isUpdate) {
//     const newTime = `${timeHourValue}:${timeMinuteValue} ${timeTypeLabel}`;
//     result = determineTimeChange(oldTime, newTime, dateTime, timeHourValue, timeMinuteValue);
//   }
//   if (!isUpdate) {
//     result = getDateTimeObject(timeTypeValue, dateTime, timeHourValue, timeMinuteValue);
//   }
//   return result;
// };

//  Determine time change (fixes AM/PM conversion issues)
export const determineTimeChange = (oldTime, newTime, dateTime, timeHourValue, timeMinuteValue) => {
  if (!dateTime || !moment(dateTime).isValid()) {
    console.error('Invalid dateTime input:', dateTime);
    return null;
  }

  let oldTimeMoment = moment(oldTime, ['hh:mm A', 'HH:mm']);
  let newTimeMoment = moment(newTime, ['hh:mm A', 'HH:mm']);

  console.log('Checking time change:', {
    oldTime: oldTimeMoment.format('hh:mm A'),
    newTime: newTimeMoment.format('hh:mm A')
  });

  const isPM = newTime.includes('PM');

  if (!oldTimeMoment || oldTimeMoment.format('hh:mm A') !== newTime) {
    const adjustedTime = getTime(dateTime, timeHourValue, timeMinuteValue, isPM);
    console.log(' Adjusted Time:', adjustedTime.format());
    return adjustedTime;
  }
  return moment(dateTime);
};

export const getDateTimeObjectGeo = (timeTypeValue, dateTime, timeHourValue, timeMinuteValue) => {
  /*
 * This function takes the following parameters:
 * timeTypeValue: string
 * dateTime: dateTime object
 * timeHourValue: string that can be cast to number
 * timeMinuteValue: string that can be cast to number
 *
 * It returns a formatted dateTime using the given params
 */
  let result = null;
  if (timeTypeValue === 'P') {
    result = getTime(dateTime, timeHourValue, timeMinuteValue, true);
  } else {
    result = getTime(dateTime, timeHourValue, timeMinuteValue);
  }
  return result;
};

//  Get date-time object (fixes PM detection bug)
export const getDateTimeObject = (timeTypeValue, dateTime, timeHourValue, timeMinuteValue) => {
  if (!dateTime || !moment(dateTime).isValid()) {
    console.error('Invalid dateTime input:', dateTime);
    return null;
  }

  const isPM = timeTypeValue === 'PM';
  return getTime(dateTime, timeHourValue, timeMinuteValue, isPM);
};

//  Get date-time object with update handling
export const getDateTimeObjectWithUpdate = (
  isUpdate, oldTime, timeTypeLabel,
  timeTypeValue, dateTime, timeHourValue, timeMinuteValue
) => {
  if (!dateTime || !moment(dateTime).isValid()) {
    console.error('Invalid dateTime input:', dateTime);
    return null;
  }

  console.log('Inside getDateTimeObjectWithUpdate');
  console.log('Params:', { isUpdate, oldTime, timeTypeLabel, timeTypeValue, dateTime, timeHourValue, timeMinuteValue });

  let result = dateTime.clone(); //  Preserve original timezone, DO NOT override

  const isPM = timeTypeLabel === 'PM';  //  Correctly detect PM value

  if (isUpdate) {
    const newTime = `${String(timeHourValue).padStart(2, '0')}:${String(timeMinuteValue).padStart(2, '0')} ${timeTypeLabel}`;
    result = determineTimeChange(oldTime, newTime, result, timeHourValue, timeMinuteValue);
  } else {
    result = getTime(result, timeHourValue, timeMinuteValue, isPM);
  }

  console.log(' Computed result:', result.format()); //  Will match the user's original timezone
  return result;
};



