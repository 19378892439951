// Dependencies
import moment from 'moment';
import Analytics from 'libs/Analytics';

import axios from 'axios';
// Services

import NudgeService from 'services/NudgeService';
import UserService from 'services/UserService';

import { camelize, getUser } from 'Utils';

function BoolIncludeFederationColumn(items) {
  const IsFederationIdIncluded = (value) => value.hasOwnProperty('federationId') && value.federationId;
  return items.some(IsFederationIdIncluded);
}

function downloadCsvFile(
  filename,
  endpoint,
  params = {},
  environment,
  FILE_NAME,
  notification,
  nudgeHeaders = { needToken: false },
) {
  if (params) {
    const urlParams = new URLSearchParams(params).toString();
    endpoint = `${endpoint}?${urlParams}`;
  }

  // const baseUrl = JSON.parse(sessionStorage.getItem('USER')) || null;
  // const { coreUrl } = baseUrl;
  // const URL = coreUrl + endpoint;

  const baseUrl = JSON.parse(sessionStorage.getItem("USER")) || {};
  const coreUrl = baseUrl.coreUrl || "http://127.0.0.1:5000";
  const URL = `${coreUrl}${endpoint}`;

  const user = getUser();
  const { core, push } = UserService.getTokens();

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const payload = {
    organization_id: user.currentOrganization.id,
    analytics_type: 'default',
    time_zone: timeZone,
  };

  const headers = {
    'User-Organization': user.currentOrganization.id,
    'Nudge-Api-Version': '2.0.0',
    'Access-Token-Core': core,
    'Access-Token-Push': push,
  };
  if (nudgeHeaders.needToken) {
    headers.Authorization = `Bearer ${core}`;
  }
  const exportClient = axios.create({
    baseURL: 'http://localhost:8080/',
    headers,
  });
  exportClient.post(URL, payload, { headers })
    .then(() => {
      console.log('Backend API successfully called:', URL);
    })
    .catch((err) => {
      if (err.response) {
        Analytics.trackApplicationError(err.message, FILE_NAME, 'downloadCsvFile');
        notification.alert(err.message, 'danger');
      } else {
        Analytics.trackApplicationError(err.message, FILE_NAME, 'downloadCsvFile');
        notification.alert(err.message, 'danger');
      }
    });
}

/**
 * Allows to download analytics as csv files - nudges received and tapped
 * Analytics coming from Keen are handle a bit different. below are some of the differences:
 *    Objects/json are not camel-case
 *    row's timestamp is within the keen attribute
 */
// old function for downloading Analytics CSV through GraphQL
// keeping this in for now since removing it would cause errors for
export const downloadAnalyticsCSV = (jsonData, fileName, headers = [], isKeenSource = true) => {
  const items = JSON.parse(jsonData);

  const replacer = (key, value) => (value || '');
  const exportHeaders = [...headers];

  if (!exportHeaders.includes('federation_id') && BoolIncludeFederationColumn(items)) exportHeaders.push('federation_id');

  const parseRow = (row) => {
    const data = [];

    const parseNumber = (n) => (n < 10 ? `0${n}` : n);

    const timestampSource = isKeenSource ? row.keen.timestamp : row.timestamp;
    const timestamp = new Date(timestampSource);

    const fullYear = timestamp.getFullYear();
    const month = parseNumber(timestamp.getMonth() + 1);
    const day = parseNumber(timestamp.getDate());
    const hours = parseNumber(timestamp.getHours());
    const minutes = parseNumber(timestamp.getMinutes());

    const date = `${fullYear}-${month}-${day}`;
    const time = `${hours}:${minutes}`;

    data.push(date);
    data.push(time);
    data.push(
      ...exportHeaders.slice(2).map((header) => (isKeenSource ? JSON.stringify(row[header], replacer) : JSON.stringify(row[camelize(header)], replacer))),
    );

    return data.join(',');
  };

  let csv = items.map(parseRow);

  csv.unshift(exportHeaders.join(','));
  csv = csv.join('\r\n');
  const csvStr = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
  const downloadCSV = document.createElement('a');
  downloadCSV.setAttribute('href', csvStr);
  downloadCSV.setAttribute('download', fileName);
  document.body.appendChild(downloadCSV);
  downloadCSV.click();
  downloadCSV.remove();
};

export const getAnalyticsQueryOptions = (eventName, filters, numberOfDays) => {
  const endDate = moment.utc().endOf('day');
  const startDate = moment.utc().startOf('day').subtract(numberOfDays, 'days');
  return {
    event_collection: eventName,
    interval: 'daily',
    timeframe: {
      end: endDate.toISOString(),
      start: startDate.toISOString(),
    },
    filters,
    timezone: 'UTC',
  };
};

export const getNudgesAnalyticsParams = (numberOfDays, jsCasing = false, nudgeId = null, campaignId = null) => {
  // we mostly use GraphQL for API interactions, but there are some special cases that we
  // use other approaches e.g. dashboard analytics export (csv file). in this case the parameters
  // casing don't match what the backend expects, so instead of using JS casing we need
  // Python casing
  const endDate = moment.utc().endOf('day');
  const startDate = moment.utc().startOf('day').subtract(numberOfDays, 'days');
  let result;
  if (jsCasing) {
    result = {
      end_date: endDate.toISOString(),
      start_date: startDate.toISOString(),
    };
    result = campaignId ? { ...result, campaign_id: campaignId } : result;
    result = nudgeId ? { ...result, message_id: nudgeId } : result;
    return result;
  }
  result = {
    endDate: endDate.toISOString(),
    startDate: startDate.toISOString(),
  };
  result = campaignId ? { ...result, campaignId } : result;
  result = nudgeId ? { ...result, messageId: nudgeId } : result;
  return result;
};

export const downloadAnalyticsDB = async (dataForAnalytics, loadingStateCallback) => {
  const inputDestructure = dataForAnalytics.analyticsParams ? dataForAnalytics.analyticsParams : dataForAnalytics;
  const
    {
      ENVIRONMENT,
      FILE_NAME,
      organizationId,
      notification,
      getNudgesReceivedQuery,
      getNudgesTappedQuery,
      getNudgesHeadersQuery,
      NUMBER_OF_DAYS_TO_SHOW_ON_DOWNLOAD,
    } = inputDestructure;
  try {
    loadingStateCallback ? loadingStateCallback(true) : null;
    const nudgeRequestHeaders = await NudgeService.getNudgesHeaders(
      getNudgesHeadersQuery,
      organizationId,
    );
    const idForParamsDetermination = (typeRequested) => {
      if (typeRequested === 'Campaign' && dataForAnalytics.type === 'Campaign') {
        return dataForAnalytics.id;
      }
      if (typeRequested === 'Nudge' && dataForAnalytics.type === 'Nudge') {
        return dataForAnalytics.id;
      }

      return null;
    };

    const nudgesAnalyticsParams = {
      ...getNudgesAnalyticsParams(NUMBER_OF_DAYS_TO_SHOW_ON_DOWNLOAD, true, idForParamsDetermination('Nudge'), idForParamsDetermination('Campaign')),
    };
    const today = moment().format('DD/MM/YYYY');
    const filename = `nudgeAnalytics - ${today}.csv`;
    setTimeout(() => downloadCsvFile(
      filename, '/nudges/data/export', nudgesAnalyticsParams, ENVIRONMENT, FILE_NAME, notification, nudgeRequestHeaders,
    ), 300);
    loadingStateCallback ? loadingStateCallback(false) : null;
  } catch (err) {
    Analytics.trackApplicationError(err.message, FILE_NAME, 'downloadAnalyticsDB');
    notification.alert(err.message, 'danger');
  }
};

function getDatesArray(startDate, endDate) {
  const dateArray = [];
  let currentDate = moment(startDate);
  const stopDate = moment(endDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

function addMissingDateCounts(datesArray, input) {
  const lookupA = input.reduce((acc, curr) => ({ ...acc, [curr.date]: curr }), {});
  return datesArray.map((date) => lookupA[date] ?? { date, count: 0 });
}

/**
 * Allows fetching nudges received and tapped to be used for the
 * Analytics coming from Keen are handle a bit different. below are some differences:
 *    Objects/json are not camel-case
 *    row's timestamp is within the keen attribute
 */

/**
   * get Analytics
   * @param {String} organizationId
   * @param {Object} notification
   * @param {String} analyticsType : 'default' = export, 'count' = analytics table and graph
   * @param {Query} getNudgesReceivedQuery
   * @param {Query} getNudgesTappedQuery
   * @param {Number} NUMBER_OF_DAYS_TO_QUERY
   * @param {String} nudgeId
   * @param {String} campaignId
   * @param {String} timeZone
   * @returns {Promise}
   */
export const getAnalyticsDB = async (
  organizationId,
  notification,
  getNudgesReceivedQuery,
  getNudgesTappedQuery,
  NUMBER_OF_DAYS_TO_QUERY,
  analyticsType = 'default',
  nudgeId = null,
  campaignId = null,
  timeZone=Intl.DateTimeFormat().resolvedOptions(),
) => {
  try {
    const nudgesAnalyticsParams = {
      ...getNudgesAnalyticsParams(NUMBER_OF_DAYS_TO_QUERY, false, nudgeId, campaignId),
    };

    let nudgesReceived = await NudgeService.getNudgesReceived(
      getNudgesReceivedQuery, organizationId, nudgesAnalyticsParams, analyticsType,timeZone,
    );


    // getNudgesReceived only returns dates with counts > 0,
    // so we add the missing date and counts here
    if (nudgesReceived.length !== NUMBER_OF_DAYS_TO_QUERY) {
      // we do NOT include today's data
      const endDate = moment().format('YYYY-MM-DD');
      const startDate = moment().subtract(NUMBER_OF_DAYS_TO_QUERY, 'days').format('YYYY-MM-DD');
      const datesArray = getDatesArray(startDate, endDate);
      nudgesReceived = addMissingDateCounts(datesArray, nudgesReceived);
    }
    let nudgesTapped = await NudgeService.getNudgesTapped(
      getNudgesTappedQuery, organizationId, nudgesAnalyticsParams, analyticsType,timeZone
    );



    if (nudgesTapped.length !== NUMBER_OF_DAYS_TO_QUERY) {
      const endDate = moment().format('YYYY-MM-DD');
      const startDate = moment().subtract(NUMBER_OF_DAYS_TO_QUERY, 'days').format('YYYY-MM-DD');
      const datesArray = getDatesArray(startDate, endDate);
      nudgesTapped = addMissingDateCounts(datesArray, nudgesTapped);
    }

    return { nudgesReceived, nudgesTapped };
  } catch (err) {
    Analytics.trackApplicationError(err.message, '', 'fetch');
    notification.alert(err.message, 'danger');
    return { nudgesReceived: [], nudgesTapped: [] };
  }
};