import { useNotification } from 'components/Notification/Notification';
import { PAGINATION } from 'Constants';

class CampaignService {
  /**
   * Get campaigns by organization ID
   * @param {Object} query
   * @param {Number} organizationId
   * @returns {Promise}
   */
  static async getCampaigns(
    query,
    organizationId,
    orderBy,
    sortOrder,
    start,
    params,
    rows = PAGINATION.ITEMS_PER_PAGE,
  ) {
    const { data } = await query.refetch({
      organizationId,
      orderBy,
      sortOrder,
      rows,
      start,
      searchParam: params,
    });
    return data.getCampaigns;
  }

  /**
   * Get campaigns by ID
   * @param {Object} query
   * @param {Number} organizationId
   * @param {Number} campaignId
   * @returns {Promise}
   */
  static async getCampaign(query, organizationId, campaignId) {
    const { data } = await query.refetch({ organizationId, campaignId });
    return data.getCampaign;
  }

  static async createCampaign(mutation, basePayload) {
    const { data } = await mutation({
      variables: {
        input: basePayload,
      },
    });
    return data.createCampaign;
  }

  static async updateCampaign(mutation, campaignId, campaignInput) {
    const { data } = await mutation({ variables: { campaignId, campaignInput } });
    return data.updateCampaign;
  }

  static async activateCampaign(mutation, campaignId, campaignInput) {
    const { data } = await mutation({ variables: { campaignId, campaignInput } });
    return data.activateCampaign;
  }

  static async reorderCampaignNudges(mutation, campaignId, reorderCampaignNudgesInput) {
    const { data } = await mutation({ variables: { campaignId, reorderCampaignNudgesInput } });
    return data.reorderCampaignNudges;
  }

  static async createCampaignNudge(mutation, campaignId, campaignNudgeInput) {
    const { data } = await mutation({ variables: { campaignId, campaignNudgeInput } });
    return data.createCampaignNudge;
  }

  static async updateCampaignNudge(mutation, campaignId, campaignNudgeInput) {
    const { data } = await mutation({ variables: { campaignId, campaignNudgeInput } });
    return data.updateCampaignNudges;
  }

  static async getCampaignNudges(query, organizationId, campaignId) {
    const { data } = await query.refetch({ organizationId, campaignId });
    return data.getCampaignNudges;
  }

  /**
   * Archive campaign
   * @param {Object} mutation
   * @param {Number} id
   * @param {Boolean} archived
   * @returns {Promise}
   */
  static async archive(mutation, id, archived) {
    const actionName = archived ? 'Archive' : 'Unarchive';
    const btnText = `${actionName} campaign`;
    const notification = useNotification();
    const des = await notification.fullscreenDialogConfirm(
      `Are you sure you want to ${actionName} this campaign?`, btnText,
    );
    if (des) {
      console.log('archived',archived);
      const { data } = await mutation({ variables: { id, input: { archived } } });
      return data.archiveCampaign;
    }
    return null;
  }

  static async deleteCampaignNudge(
    mutation,
    messageName,
    campaignId,
    nudgeId,
    campaignNudges,
    setOrderOfNudges,
  ) {
    const notification = useNotification();
    const des = await notification.fullscreenDialogConfirmCampaignDelete(
      `${messageName}`,
      'Are you sure want to delete this nudge from the campaign? This action cannot be undone.',
      'Delete nudge',
    );

    if (des) {
      // if ok then we set state to reduced nudge Object
      // removes deleted campaign nudge from campaign nudge list
      const { hits } = campaignNudges;
      const filteredNudges = hits.filter((currNudge) => currNudge.actionId !== nudgeId);
      // Order nudges to be in line sequence from a filtered list
      const orderedNudges = filteredNudges.map((nudge, index) => {
        // this will set to state on successful response ^ (ordered nudges)
        const alteredNudge = { ...nudge, sequenceNum: index += 1 };
        return alteredNudge;
      });

      const reducedNudges = orderedNudges.reduce((seed, nudgeEntry) => {
        // this will be sent to backend
        seed.push({
          nudgeId: parseInt(nudgeEntry.actionId),
          sequenceNum: nudgeEntry.sequenceNum,
        });
        return seed;
      }, []);

      // update state containing list of campaign nudges
      setOrderOfNudges({
        hits: reducedNudges,
        results: reducedNudges.length,
      });

      const reorderCampaignNudgesInput = {
        campaignId: parseInt(campaignId),
        campaignNudgeOrder: reducedNudges,
      };
      const { data } = await mutation({ variables: { campaignId, reorderCampaignNudgesInput } });
      return data.reorderCampaignNudges;
    }

    return null;
  }

  static async getCampaignTemplates(query, isDraft) {
    const { data } = await query.refetch({ isDraft });
    return data.getCampaignTemplates;
  }

  static async updateCampaignTemplate(mutation, templateId, campaignTemplateInput, actionName) {
    const notification = useNotification();
    const btnText = 'Save Campaign';
    const des = await notification.fullscreenDialogConfirm(
      `Are you sure you want to ${actionName} this template?`, btnText,
    );
    if (des) {
      const { data } = await mutation({ variables: { templateId, campaignTemplateInput } });
      return data.updateCampaignTemplate;
    }
    return null;
  }

  static async createTemplateFromCampaign(mutation, campaignId, campaignTemplateInput, actionName) {
    const notification = useNotification();
    const btnText = 'Create campaign';
    const des = await notification.fullscreenDialogConfirm(
      `Are you sure you want to ${actionName} this template?`, btnText,
    );
    if (des) {
      const { data } = await mutation({ variables: { campaignId, campaignTemplateInput } });
      return data.createTemplateFromCampaign;
    }
    return null;
  }

  static async createCampaignFromTemplate(mutation, templateId, createCampaignFromTemplateInput) {
    // TODO: try catch here with notification for error on creation?
    const { data } = await mutation({ variables: { templateId, createCampaignFromTemplateInput } });
    return data.createCampaignFromTemplate;
  }

  static async deleteCampaignTemplateWork(templateId, campaignTemplateInput, originalTemplateValues, actionName, callback, setShow) {
    const notification = useNotification();
    const btnText = `${actionName} changes`;
    const des = await notification.fullscreenDialogConfirm(
      'Are you sure you want to erase these template changes?', btnText,
    );
    if (des) {
      setShow(false);
    } else {
      return null;
    }
    setShow(false);
    return null;
  }

  static async getCampaignAnalytics(query, campaignId) {
    const { data, error } = await query.refetch({ campaignId });
    return data.getCampaignAnalytics;
  }

  static async updateCampaignOriginalTemplate(mutation, templateId, updateTemplateFromCampaignInput) {
    const { data } = await mutation({ variables: { templateId, updateTemplateFromCampaignInput } });
    return data.updateCampaignOriginalTemplate;
  }
}

export default CampaignService;
