import { useEffect, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";  // Use useHistory for v5
import { useQuery } from "@apollo/client";
import { GET_NUDGES_HEADERS } from "../../graphql/queries/nudges";
import { useNotification } from "components/Notification/Notification";
import { getUser } from "Utils";
import NudgeService from "../../services/NudgeService";
import UserService from "../../services/UserService";
import moment from "moment";
import axios from "axios";

const FILE_NAME = "DownloadCsvRedirect.jsx";

/**
 * Downloads CSV file from API
 */
const downloadCsvFile = async (filename, endpoint, notification, headers, history) => {
  try {
    const baseUrl = JSON.parse(sessionStorage.getItem("USER")) || {};
    const coreUrl = baseUrl.coreUrl || "http://127.0.0.1:5000";
    const URL = `${coreUrl}${endpoint}`;

    const exportClient = axios.create({ baseURL: coreUrl, headers });
    const response = await exportClient.get(endpoint, { headers });

    if (response.status === 200 && response.data?.presigned_url) {
      const presignedUrl = response.data.presigned_url;
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

      if (isSafari) {
        // Create a hidden iframe to force Safari to handle the file download
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        iframe.src = presignedUrl;
        document.body.appendChild(iframe);

        // Remove iframe after a delay to prevent memory leaks
        setTimeout(() => {
          document.body.removeChild(iframe);
        }, 5000);
      } else {
        // Normal browsers (Chrome, Firefox, Edge) - Use anchor download
        const link = document.createElement("a");
        link.href = presignedUrl;
        link.setAttribute("download", filename);
        link.style.display = "none";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      notification.fullscreenDialog(
        'Success!',
        'Download Successful!',
        '',
        'OK',
        () => history.push("/")
      );
    } else {
      console.error("Failed to retrieve presigned URL. Response:", response);
      notification.fullscreenDialog(
        'Failure',
        'The analytics export you requested could not be generated due to an issue. Please try again or email support@larky.com for further assistance.',
        '',
        'OK',
        () => history.push("/")
      );
    }
  } catch (err) {
    console.error("Error fetching presigned URL:", err.message);
    notification.fullscreenDialog(
      'Failure',
      "The analytics export you requested could not be generated due to an issue. Please try again or email support@larky.com for further assistance.",
      '',
      'Ok',
      () => history.push("/")
    );
  }
};


/**
 * Fetches analytics data and triggers CSV download
 */
const downloadAnalyticsDB = async (notification, getNudgesHeadersQuery, storedEndpoint, history) => {
  try {
    const user = getUser();
    if (!user?.currentOrganization?.id) throw new Error("Invalid Organization ID");

    // Fetch headers
    const originalHeaders = await NudgeService.getNudgesHeaders(getNudgesHeadersQuery, user.currentOrganization.id);
    // Get tokens from UserService as a fallback
    const { core, push } = UserService.getTokens();
    // Create a new headers object to avoid modifying a potentially read-only object
    const nudgeRequestHeaders = {
      ...originalHeaders,
      accessTokenCore: originalHeaders.accessTokenCore || core,
      accessTokenPush: originalHeaders.accessTokenPush || push,
    };

    if (!nudgeRequestHeaders.accessTokenCore) {
      throw new Error("Missing authentication tokens in headers.");
    }

    const today = moment().format("DD/MM/YYYY");
    const filename = `nudgeAnalytics-${today}.csv`;

    const apiHeaders = {
      "User-Organization": user.currentOrganization.id,
      "Nudge-Api-Version": "2.0.0",
      "Access-Token-Core": nudgeRequestHeaders.accessTokenCore,
      "Access-Token-Push": nudgeRequestHeaders.accessTokenPush,
      ...(nudgeRequestHeaders.needToken && { Authorization: `Bearer ${nudgeRequestHeaders.accessTokenCore}` }),
    };

    setTimeout(() => {
      downloadCsvFile(filename, storedEndpoint, notification, apiHeaders, history);
    }, 300);
  } catch (err) {
    console.error(`Error in downloadAnalyticsDB: ${err.message}`);
    notification.alert(err.message, "danger");
  }
};

/**
 * Handles CSV download when user is authenticated
 */
function DownloadCsvRedirect() {
  const location = useLocation();
  const history = useHistory();  // Use useHistory for React Router v5
  const user = getUser();
  const notification = useNotification();
  const getNudgesHeadersQuery = useQuery(GET_NUDGES_HEADERS, { skip: true });

  const handleDownload = useCallback(() => {
    if (!user) {
      const redirectUrl = location.pathname + location.search;
      sessionStorage.setItem("redirectAfterLogin", redirectUrl);
      localStorage.setItem("redirectAfterLogin", redirectUrl);
      history.replace("/login");  // Use history.replace in v5
    } else {
      let storedEndpoint = sessionStorage.getItem("redirectAfterLogin") || localStorage.getItem("redirectAfterLogin");

      if (!storedEndpoint) {
        console.warn("No valid redirect URL found in sessionStorage. Using default endpoint.");
        storedEndpoint = "/"; // Fallback
      }

      downloadAnalyticsDB(notification, getNudgesHeadersQuery, storedEndpoint, history);
    }
  }, [user, location, history, notification, getNudgesHeadersQuery]);

  useEffect(() => {
    handleDownload();
  }, []);

  return null;
}

export default DownloadCsvRedirect;
