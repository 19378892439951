import { useNotification } from 'components/Notification/Notification';
import { PAGINATION } from 'Constants';
import { formatNumber } from 'Utils';
import React from "react";
import {NonceProvider} from "react-select";

class NudgeService {
  /**
   * Get all nudges
   * @param {Object} query
   * @param {String} orderBy
   * @param {String} sortOrder
   * @param {Number} start
   * @param {Object} params
   * @returns {Promise}
   */
  static async all(
    query,
    orderBy,
    sortOrder,
    start,
    params,
    rows = PAGINATION.ITEMS_PER_PAGE,
  ) {
    const { data } = await query.refetch({
      orderBy,
      sortOrder,
      rows,
      start,
      searchParam: params,
    });
    return data.searchNudges;
  }

  /**
   * Get actions with message and conditions
   * @param {Object} query
   * @param {String} actionId
   * @returns {Promise}
   */
  static async getNudge(query, actionId) {
    const { data } = await query.refetch({ id: actionId });
    return data.getNudge;
  }

  static async getNudgesHeaders(query, organizationId) {
    const { data } = await query.refetch({ organizationId });
    return data.getNudgesHeaders;
  }

  static async getNudgeScore(query, body, title) {
    const { data, error } = await query.refetch({ body, title });
    return data.getNudgeScore;
  }

  static async getNudgeAssist(query, text) {
    const { data } = await query.refetch({ text })
    return data.getNudgeAssist;
  }

  static async getNudgeAnalytics(query, actionId) {
    const { data, error } = await query.refetch({ actionId });
    return data.getNudgeAnalytics;
  }

  static async getGeoBasedAnalytics(query, actionId) {
    const { data, error } = await query.refetch({ actionId });
    return data.getGeoBasedAnalytics;
  }

  /**
   * Get nudges received (analytics)
   * @param {Object} query
   * @param {object} params
   * @param {string} organizationId
   * @param {string} analyticsType
   * @param {string} timeZone
   * @returns {Promise}
   */
  static async getNudgesReceived(query, organizationId, params, analyticsType = 'default',
                                 timeZone=Intl.DateTimeFormat().resolvedOptions()) {
    const { data } = await query.refetch({ organizationId, searchParam: params, analyticsType,timeZone });
    return data.getNudgesReceived;
  }

    /**
   * Get nudge/campaign categories
   * @param {Object} query
   * @returns {Promise}
   */
  static async getNudgeAndCampaignCategories(query) {
    const { data } = await query.refetch();
    const allFormatted = data.getNudgeAndCampaignCategories.categories.reduce((seed, category) => {
      const formattedCat = {value: category.categoryKey, label: category.categoryValue};
      seed.push(formattedCat)
      return seed
    }, []);
    return allFormatted
  };

  /**
   * Get nudges tapped (analytics)
   * @param {Object} query
   * @param {object} params
   * @param {string} organizationId
   * @param {string} analyticsType
   * @param {string} timeZone
   * @returns {Promise}
   */
  static async getNudgesTapped(query, organizationId, params, analyticsType = 'default'
                               ,timeZone=Intl.DateTimeFormat().resolvedOptions()) {
    const { data } = await query.refetch({ organizationId, searchParam: params, analyticsType,timeZone });
    return data.getNudgesTapped;
  }

  /**
   * Create Time-based Nudge
   * @param {Object} mutation
   * @param {Object} basePayload
   * @param {String} basePayload.name
   * @param {String} basePayload.description
   * @param {String} basePayload.title
   * @param {String} basePayload.body
   * @param {String} basePayload.url
   * @param {Boolean} basePayload.suppressNotification
   * @param {String} basePayload.organizationId
   * @param {String} basePayload.environment
   * @param {Boolean} isDraft
   * @param {Boolean} scheduleNow
   * @param {Date || null} dateTime
   */
  static async createTimeBasedNudge(
    mutation,
    basePayload,
    isDraft,
    scheduleNow,
    dateTime,
    messageCategoryId
  ) {
    const { data } = await mutation({
      variables: {
        input: {
          ...basePayload,
          isDraft,
          scheduleNow,
          dateTime,
          messageCategoryId
        },
      },
    });
    return data.createTimeBasedNudge;
  }


  /**
   * Check Nudge limits for alert based nudges
   * @param {Promise} mutation
   * @param {Object} input
   * @returns {Promise}
   */


  static async checkNudgeLimits(mutation, input) {
    console.log('input', input);

    const { data } = await mutation({
        variables: {
            input,
        },
    });

    console.log('data', data); // Ensure this logs correctly

    return data?.checkNudgeLimits; // Explicitly return the extracted response
}


  /**
   * Create Geolocation Nudge
   * @param {Object} mutation
   * @param {Object} basePayload
   * @param {String} basePayload.name
   * @param {String} basePayload.description
   * @param {String} basePayload.title
   * @param {String} basePayload.body
   * @param {String} basePayload.url
   * @param {Boolean} basePayload.suppressNotification
   * @param {String} basePayload.organizationId
   * @param {String} basePayload.environment
   * @param {Boolean} isDraft
   * @param {Number} radius
   * @param {Number[]} selectedLocationsIds
   * @param {Number[]} selectedWeekDays
   * @param {String} startTime
   * @param {String} endTime
   * @param {Date || null} dateTimeRangeStart
   * @param {Date || null} dateTimeRangeEnd
   * @param {Number} delay
   */
  static async createGeolocationNudge(
    mutation,
    basePayload,
    isDraft,
    radius,
    selectedLocationsIds,
    selectedWeekDays,
    startTime,
    endTime,
    dateTimeRangeStart,
    dateTimeRangeEnd,
    delay,
    messageCategoryId,
    tapBehavior
  ) {
    const { data } = await mutation({
      variables: {
        input: {
          ...basePayload,
          isDraft,
          radius,
          selectedLocationsIds,
          selectedWeekDays,
          startTime,
          endTime,
          dateTimeRangeStart,
          dateTimeRangeEnd,
          delay,
          messageCategoryId,
          tapBehavior
        },
      },
    });
    return data.createGeolocationNudge;
  }

  /**
   * Update Time-based Nudge
   * @param {Object} mutation
   * @param {String} actionId
   * @param {String} messageId
   * @param {String} conditionId
   * @param {Object} basePayload
   * @param {String} basePayload.name
   * @param {String} basePayload.description
   * @param {String} basePayload.title
   * @param {String} basePayload.body
   * @param {String} basePayload.url
   * @param {Boolean} basePayload.suppressNotification
   * @param {Boolean} isDraft
   * @param {Boolean} scheduleNow
   * @param {Date || null} dateTime
   */
  static async updateTimeBasedNudge(
    mutation,
    actionId,
    messageId,
    conditionId,
    basePayload,
    isDraft,
    scheduleNow,
    dateTime,
    messageCategoryId
  ) {
    const { data } = await mutation({
      variables: {
        actionId,
        messageId,
        conditionId,
        input: {
          ...basePayload,
          isDraft,
          scheduleNow,
          dateTime,
          messageCategoryId
        },
      },
    });
    return data.updateTimeBasedNudge;
  }

  /**
   * Update Geolocation Nudge
   * @param {Object} mutation
   * @param {String} actionId
   * @param {String} messageId
   * @param {Object} basePayload
   * @param {String} basePayload.name
   * @param {String} basePayload.description
   * @param {String} basePayload.title
   * @param {String} basePayload.body
   * @param {String} basePayload.url
   * @param {Boolean} basePayload.suppressNotification
   * @param {Number} radius
   * @param {Number[]} selectedWeekDays
   * @param {Number[]} selectedLocationsIds
   * @param {String} startTime
   * @param {String} endTime
   * @param {Date || null} dateTimeRangeStart
   * @param {Date || null} dateTimeRangeEnd
   * @param {Boolean} isDraft
   * @param {Int} delay
   */
  static async updateGeolocationNudge(
    mutation,
    actionId,
    messageId,
    basePayload,
    isDraft,
    radius,
    selectedWeekDays,
    selectedLocationsIds,
    startTime,
    endTime,
    dateTimeRangeStart,
    dateTimeRangeEnd,
    delay,
    messageCategoryId,
    tapBehavior
  ) {
    const { data } = await mutation({
      variables: {
        actionId,
        messageId,
        input: {
          ...basePayload,
          isDraft,
          radius,
          selectedWeekDays,
          selectedLocationsIds,
          startTime,
          endTime,
          dateTimeRangeStart,
          dateTimeRangeEnd,
          delay,
          messageCategoryId,
          tapBehavior
        },
      },
    });
    return data.updateGeolocationNudge;
  }

  /**
   * Archive nudge
   * @param {Object} mutation
   * @param {Number} id
   * @param {Boolean} archived
   * @returns {Promise}
   */
  static async archive(mutation, id, archived) {
    const { data } = await mutation({ variables: { id, input: { archived } } });
    return data.archiveNudge;
  }

  /**
   * Delete nudge
   * @param {Object} mutation
   * @param {String} id
   * @returns {Promise}
   */
  static async delete(mutation, id) {
    const notification = useNotification();
    const des = await notification.fullscreenDialogConfirm(
      'Are you sure you want to delete this nudge?',
      'Delete nudge',
    );

    if (des) {
      const { data } = await mutation({ variables: { id } });
      return data.deleteNudge;
    }

    return null;
  }

  /**
   * Clone nudge
   * @param {Object} mutation
   * @param {String} id
   * @param {Promise} archived
   */
  static async clone(mutation, id){
    const { data } = await mutation({variables: { id }})
    return data.cloneNudge
  }

  /**
   * Archive many nudges.
   * @param {Object} mutation
   * @param {number[]} ids
   * @returns {Promise}
   */
  static async archiveMany(mutation, ids, archived = true) {
    return Promise.all(
      ids.map((id) => mutation({ variables: { id, input: { archived } } })),
    );
  }

  /**
   * Delete many nudges.
   * @param {Object} mutation
   * @param {number[]} ids
   * @returns {Promise}
   */
  static async deleteMany(mutation, ids) {
    const notification = useNotification();
    const des = await notification.fullscreenDialogConfirm(
      `Are you sure you want to delete ${ids.length} nudges?`,
      'Delete nudges',
    );

    if (des) {
      return Promise.all(ids.map((id) => mutation({ variables: { id } })));
    }

    return null;
  }

  /**
   * Warning before creating a nudge.
   * @param {string} numberOfRecipients
   * @param {string} deliveryDate
   * @param {string} startingTime
   * @returns {Boolean}
   */

  static async warningBeforeCreatingNudge(numberOfRecipients, deliveryDate, startingTime) {
    const notification = useNotification();
    return await notification.fullScreenDialogCardConfirm(
      'Attention!',
      `This nudge will be sent to approximately ${formatNumber(numberOfRecipients)} recipients on ${deliveryDate} starting at ${startingTime}`,
      'Are you sure you want to continue?',
      'Continue',
    );
  }

  static async timeWarningBeforeCreatingNudge(startingTime) {
    const notification = useNotification();
    const subtitle = (
    <>
        <p>
            You've scheduled this nudge for <strong>{startingTime.replace(" ", "\u00A0")}</strong>.
        </p>
        <p>
            This is outside the recommended hours of <strong>7:00&nbsp;AM to 10:00&nbsp;PM</strong>.
        </p>
    </>
);


    return await notification.fullScreenDialogCardConfirm(
      'Attention!',
      subtitle,
      'Are you sure you want to continue?',
      'Continue',
    );
  }


  static async warningBeforeCreatingNudgeLite(count, max) {
    const notification = useNotification();

    let subtitle = '';
    let confirmButtonText = 'Continue'; // Default button text

    if (count === max) {
        subtitle = (
            <>
                <p>
                    You have already sent or scheduled {count} nudges for this month, which is the limit allowed by your license.
                    This new nudge will still be sent too, but you might consider upgrading to the enhanced version of the platform,
                    which includes unlimited usage and adds several additional powerful capabilities.
                    Please contact <a href="mailto:support@larky.com">support@larky.com</a> for more details!
                </p>

            </>
        );

        return await notification.fullScreenDialogCardConfirm(
        'Notice!',
        subtitle,
        'Are you sure you want to continue?',
        confirmButtonText, // Dynamically set button text
    );
    }
    if (count > max) {
        subtitle = (
            <>
                <p>
                    You have already sent or scheduled {count} nudges for this month, which exceeds the {max}-nudge limit
                    allowed by your license.This new nudge will be saved as a Draft. It can be updated and sent next month.
                </p>
                <p>
                    You might consider upgrading to the enhanced version of the platform, which includes unlimited usage
                    and adds several additional powerful capabilities. Please contact <a href="mailto:support@larky.com">support@larky.com</a> for more details!
                </p>
            </>
        );

      return await notification.fullScreenDialogCardConfirm(
        'Notice!',
        subtitle,
        null, // No secondary button
        'Save as Draft' ,
          false,
    );
    }
  }



}




export default NudgeService;