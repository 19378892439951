// import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import { Redirect, useLocation, useHistory } from 'react-router-dom';
// import DownloadCsvRedirect from '../pages/Login/DownloadCsvRedirect';
//
// function PublicRoute({ isAuth, children }) {
//   const location = useLocation();
//   const history = useHistory();
//   const [redirectPath, setRedirectPath] = useState(null);
//   const storedRedirect = sessionStorage.getItem("redirectAfterLogin");
//
//   console.log("PublicRoute Rendered");
//   console.log("isAuth:", isAuth);
//   console.log("Current Pathname:", location.pathname);
//   console.log("Stored Redirect Path:", storedRedirect);
//
//   useEffect(() => {
//     if (isAuth && storedRedirect) {
//       console.log("Authenticated. Found stored redirect:", storedRedirect);
//       setRedirectPath(storedRedirect);
//       sessionStorage.removeItem("redirectAfterLogin"); // Prevent infinite loop
//     }
//   }, [isAuth]);
//
//   //  If the user is **not authenticated**, show the login page.
//   if (!isAuth) {
//     console.log("User is not authenticated. Rendering children.");
//     return children;
//   }
//
//   //  If the user **just logged in**, wait for the stored redirect to process.
//   if (isAuth && storedRedirect && !redirectPath) {
//     console.log("Waiting for redirect to be set...");
//     return null; //  TEMPORARILY RENDER NOTHING to prevent flicker
//   }
//
//   //  If we have a redirect path, go there immediately.
//   if (redirectPath) {
//     console.log("Redirecting to stored path:", redirectPath);
//     return <Redirect to={redirectPath} />;
//   }
//
//   //  If already on `/download_csv`, load the downloader component.
//   if (location.pathname.includes('/download_csv')) {
//     console.log("User is authenticated and on /download_csv. Starting download...");
//     return <DownloadCsvRedirect />;
//   }
//
//   //  Default case: Redirect to the home page.
//   console.log("User is authenticated but no stored redirect found. Redirecting to home page...");
//   return <Redirect to="/" />;
// }
//
// PublicRoute.propTypes = {
//   isAuth: PropTypes.bool.isRequired,
//   children: PropTypes.node.isRequired,
// };
//
// export default PublicRoute;
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

function PublicRoute({ isAuth, children }) {
  if (isAuth) {
    return <Redirect to="/" />;
  }
  return children;
}

PublicRoute.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default PublicRoute;
