/**
 * AnalyticsModal
 *
 * Modal component that contains Analytics related to a Nudge(message)
 *
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Container, Modal, Row } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { useQuery } from '@apollo/client';
import QuickviewAnalyticsSection from '../../../Home/components/QuickviewAnalyticsSection';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import { downloadAnalyticsDB } from '../../../../DashboardAnalyticsUtils';
import styles from './AnalyticsModal.module.scss';
import UserService from '../../../../services/UserService';
import { getUser, getUserFeature } from '../../../../Utils';
import Larky from '../../../../components/Larky';
import { GET_ALL_FEATURE_FLAGS_WITH_USER_ID } from '../../../../graphql/queries/organizations';
import {useNotification} from "../../../../components/Notification/Notification";
import {useHistory} from "react-router-dom";

function AnalyticsModal({
  organizationId,
  nudgeId,
  actionId,
  campaignId,
  show,
  title,
  text,
  disableAnalyticsExport,
  callback,
  downloadAnalyticsParams,
}) {
  const user = getUser();
  const getAllFeatureFlagsQuery = useQuery(GET_ALL_FEATURE_FLAGS_WITH_USER_ID, { skip: true });
  const notification = useNotification();
  const history = useHistory();
  const [userHasExportAnalytics, setUserHasExportAnalytics] = useState(false);
  const [exportAnalyticsToolTipText, setExportAnalyticsToolTipText] = useState(false);
  const [exportAnalyticsUrl, setExportAnalyticsUrl] = useState(false);
  const [exportAnalyticsLoading, setExportAnalyticsLoading] = useState(false);

  useEffect(async () => {
    const hasExportAnalytics = await getUserFeature('export_analytics');
    await setUserHasExportAnalytics(!!hasExportAnalytics);
  }, [user.featureFlags]);

  useEffect(async () => {
    const exportAnalyticsFeatureInfo = await UserService.getFeatureFlagInfoWithUserId(getAllFeatureFlagsQuery, user.id, 'export_analytics');
    setExportAnalyticsToolTipText(exportAnalyticsFeatureInfo.tooltip);
    setExportAnalyticsUrl(exportAnalyticsFeatureInfo.tooltipUrl);
  }, [user.id]);

  const onExportAnalyticsClick = () => {
    if (userHasExportAnalytics) {
      notification.dialog(
            'Success!',
            'You’ll receive an email notification when your export is complete and ready for download. This usually only takes a few minutes!',
            '',
            'Ok',
            () => {history.goBack()},
          );
      return downloadAnalyticsDB(downloadAnalyticsParams);
    }
    if (!userHasExportAnalytics) {
      window.open(exportAnalyticsUrl, '_blank');
    }
    return null;
  };

  const createToolTipText = (textBody, url) => `${exportAnalyticsToolTipText} Click to learn more!`;

  const analyticsHeaders = [
    { title: '', icon: null },
    { title: 'Unique Recipients', icon: null },
    { title: 'Total Impressions', icon: null },
    { title: 'Engagements', icon: null },
    { title: 'Engagement Rate', icon: null },
  ];

  return (
    <>
      <Modal
        show={show}
        size="xl"
        onHide={callback}
        animation={false}
        backdrop="static"
        className={styles['modal-dialog']}
      >
        <Modal.Body>
          <Container fluid>
            <Modal.Header closeButton>
              <Row>
                <h1>
                  {' '}
                  {title || 'Analytics'}
                  {' '}
                </h1>
              </Row>
            </Modal.Header>
            <Row className={styles['row-header']}>
              <h5 style={{ color: '#4f586a' }}>
                {text}
              </h5>
              {!disableAnalyticsExport ? (
                <>
                  <Larky.Button
                    onClick={() => onExportAnalyticsClick()}
                    data-tip={!userHasExportAnalytics ? createToolTipText() : null}
                    className={!userHasExportAnalytics ? styles['disabled-button'] : null}
                  >
                    Export Analytics
                  </Larky.Button>
                </>
              ) : null}
            </Row>
          </Container>
          <hr style={{ width: '100%', border: '1px solid black' }} />
          <QuickviewAnalyticsSection
            organizationId={organizationId}
            nudgeId={nudgeId}
            actionId={actionId}
            campaignId={campaignId}
            isModal
            hasAnalytics={userHasExportAnalytics}
            toolTipMessage={createToolTipText()}
            url={exportAnalyticsUrl}
            exportAnalyticsLoading={exportAnalyticsLoading}
            tableHeaders={analyticsHeaders}
          />
          <Row style={{ justifyContent: 'center', width: '100%', paddingTop: '20px' }}>
            <CustomButton
              dataCy="primary"
              variant="contained"
              size="sm"
              text="OK"
              onClick={() => callback()}
            />
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

AnalyticsModal.propTypes = {
  organizationId: PropTypes.number.isRequired,
  nudgeId: PropTypes.string,
  actionId: PropTypes.string,
  campaignId: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  show: PropTypes.bool.isRequired,
  disableAnalyticsExport: PropTypes.bool,
  callback: PropTypes.func,
  downloadAnalyticsParams: PropTypes.shape(
    {
      id: PropTypes.string,
      analyticsParams: PropTypes.shape({}).isRequired,
      type: PropTypes.string.isRequired,
    },
  ),
};

AnalyticsModal.defaultProps = {
  disableAnalyticsExport: false,
  nudgeId: null,
  actionId: null,
  campaignId: null,
  callback: null,
  title: null,
  text: null,
  downloadAnalyticsParams: null,
};

export default AnalyticsModal;
