// Dependencies
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useHistory, Link, useLocation } from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import { getUser } from 'Utils';

// Components
import { Form, Container, Row } from 'react-bootstrap';
import CustomCard from 'components/CustomCard/CustomCard';
import Larky from 'components/Larky';

// Constants
import {
  REQUIRED_FIELD,
  UPDATE_PASSWORD_LOGIN_DISPATCHER,
  TOKEN_EXPIRED_LOGIN_DISPATCHER,
  SESSION_EXPIRED_LOGIN_DISPATCHER,
} from 'Constants';

// GraphQL
import { LOGIN_MUTATION } from 'graphql/mutations/users';

// Contexts
import { useAuthMachineValue } from '../../contexts/auth-machine.context.ts';

const validationSchema = () => Yup.object({
  email: Yup.string().email().required(REQUIRED_FIELD),
  password: Yup.string().trim().required(REQUIRED_FIELD),
});

function Login() {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const dispatcher = decodeURIComponent(params.get('dispatcher') || '');
  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const [, send] = useAuthMachineValue();

  let title = 'Login';
  let subtitle = null;
  let size = 'sm';
  let type = null;

  switch (dispatcher) {
    case UPDATE_PASSWORD_LOGIN_DISPATCHER:
      title = 'Success!';
      subtitle = 'You have successfully updated your nudge password. You can now log in with your new password!';
      size = 'lg';
      break;
    case TOKEN_EXPIRED_LOGIN_DISPATCHER:
      title = 'Please login again';
      subtitle = 'Authentication token expired';
      size = 'sm';
      type = 'alert';
      break;
    case SESSION_EXPIRED_LOGIN_DISPATCHER:
      title = 'Please login again';
      subtitle = 'Your session has expired due to inactivity';
      size = 'sm';
      type = 'alert';
      break;
    default:
      break;
  }

  useEffect(() => {
  const user = getUser();
  let redirectTo = sessionStorage.getItem("redirectAfterLogin") || localStorage.getItem("redirectAfterLogin");

  if (user && redirectTo) {
    // Move removal AFTER redirection
    setTimeout(() => {
      window.location.assign(redirectTo);
      sessionStorage.removeItem("redirectAfterLogin");
      localStorage.removeItem("redirectAfterLogin");
    }, 500);
  }
}, [history, location]);



  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: async ({ email, password }) => {
      send('LOGIN', {
        withCredentials: true,
        mutation: loginMutation,
        credentials: {
          email,
          password,
        },
      });

    },

  });

  return (
    <CustomCard
      title={title}
      subtitle={subtitle}
      size={size}
      type={type}
      isForm
    >
      <Form onSubmit={formik.handleSubmit}>
        <Larky.Form.Input
          type="email"
          name="email"
          title="Email address"
          placeholder="Email address"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email}
        />
        <Larky.Form.Input
          type="password"
          name="password"
          title="Password"
          placeholder="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.errors.password}
        />

        <Form.Group as={Container} className="mt-5">
          <Row className="justify-content-center">
            <Larky.Button
              id="login-button"
              dataCy="login"
              type="submit"
              size="lg"
              isLoading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            >
              Log in
            </Larky.Button>
          </Row>
        </Form.Group>

        <Form.Group className="mt-2 mb-0">
          <Row className={"justify-content-center mt-4"}>
            <Link data-cy="forgot-password-link" to="/forgot-password">Forgot password?</Link>
          </Row>
          <Row className={"justify-content-center mt-4"}>
            <span className={"text-center"} style={{paddingLeft: '13%', paddingRight: '13%'}}>Your access to this Larky Software is conditioned on your acceptance of the terms and conditions at
             <a href={"https://nudge.larky.com/saas-license-agreement "} target="_blank"> https://nudge.larky.com/saas-license-agreement.</a>
              </span>
          </Row>
        </Form.Group>
      </Form>
    </CustomCard>
  );
}

export default Login;
