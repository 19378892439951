import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Row, Col, Container, Card } from 'react-bootstrap';
import HubSpotModal from './components/HubSpotModal'; // Adjust the path as necessary

// Services
import Analytics from 'libs/Analytics';
import { useAuthMachineValue } from 'contexts/auth-machine.context.ts';
import OrganizationService from 'services/OrganizationService';

// GraphQL
import { UPDATE_MESSAGE_CAPPING_MUTATION } from 'graphql/mutations/organizations';
import { GET_MESSAGE_CAPPING_LIMIT, GET_ORGANIZATION_PROFILE_PICTURE } from 'graphql/queries/organizations';

import Larky from 'components/Larky';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomCard from 'components/CustomCard/CustomCard';
import CustomSection from 'components/CustomSection/CustomSection';
import { useNotification } from 'components/Notification/Notification';

// GraphQL
import {INVITE_USER_MUTATION, VERIFY_AUTHENTICATOR_CODE, VERIFY_HUBSPOT_CALLBACK} from 'graphql/mutations/users';

// Styles
import styles from './OrganizationSettings.module.scss';
import { REQUIRED_FIELD, ROUTES, userDataMockUp } from '../../Constants';
import UserService from '../../services/UserService';
import { UPDATE_USER_MUTATION } from '../../graphql/mutations/users';
import { GET_USER, GET_USERS, GET_CONDUIT_DATA_SOURCES } from '../../graphql/queries/users';
import { getUser } from '../../Utils';
import NoPhotoIcon from '../../assets/img/no-photo-icon.svg';
import OrganizationProfilePicture from './components/OrganizationProfilePicture.jsx';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
import CustomField from '../../components/CustomField/CustomField';
import UserRow from '../Users/components/UserRow/UserRow';
import CustomTable from '../../components/CustomTable/CustomTable';
import DataSourceRow from "./components/DataSourceRow";

const MessageLabel = ({ title, style, children }) => (
  <div className={styles['message-label']} style={style}>
    <h6 style={{ width: '100%', textAlign: 'left' }}>{title}</h6>
    {children}
  </div>
);

// Formik Validation
const validationSchema = () => Yup.object({
  limitDay: Yup.number(),
  limitWeek: Yup.number()
    .when('limitDay', (limitDay, schema) => (
      limitDay ? schema.min(limitDay, 'The Weekly Limit must be greater than the Daily Limit')
        : schema
    )),
  limitMonth: Yup.number()
    .when('limitDay', (limitDay, schema) => (
      limitDay ? schema.min(limitDay, 'The Monthly Limit must be greater than the Daily Limit and Weekly Limit')
        : schema
    ))
    .when('limitWeek', (limitWeek, schema) => (
      limitWeek ? schema.min(limitWeek, 'The Monthly Limit must be greater than the Daily Limit and Weekly Limit')
        : schema
    )),
});

function OrganizationSettings({ organizationId }) {
  const history = useHistory();
  const location = useLocation();
  const [currentAuth] = useAuthMachineValue();
  const { user } = currentAuth.context;
  const userIsViewOnly = user && user.featureFlags && user.featureFlags.nudges_timed === 1;
  const [includeInactiveUsers, setIncludeInactiveUsers] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false); // State for managing modal visibility

  const [updateMessageCappingMutation] = useMutation(UPDATE_MESSAGE_CAPPING_MUTATION);
  const getMessageCappingLimitQuery = useQuery(GET_MESSAGE_CAPPING_LIMIT, { skip: true });
  const getOrganizationPictureQuery = useQuery(GET_ORGANIZATION_PROFILE_PICTURE, { skip: true });
  const getUsersByOrg = useQuery(GET_USERS, { skip: true });
  const GetConduitDataQuery = useQuery(GET_CONDUIT_DATA_SOURCES, { skip: true });

  const [initialValues, setInitialValues] = useState({});
  const notification = useNotification();
  const [inviteUserMutation] = useMutation(INVITE_USER_MUTATION);
  const [verifyHubspotCallback] = useMutation(VERIFY_HUBSPOT_CALLBACK);

  const inviteUserCallback = async (data, currentUser, inviteMutation) => {
    try {
      const {
        email,
        firstName,
        lastName,
        adminUser,
        portalRole,
      } = data;

      const organizationId = currentUser && currentUser.currentOrganization.id;
      const reinvite = 1
      await UserService.inviteUser(inviteMutation, {
        email,
        organizationId,
        firstName,
        lastName,
        isAdmin: adminUser,
        portalRole,
        loggedInUserid: id,
        reinvite,
      });
      notification.fullscreenDialog(
        'Success!',
        'You have successfully invited a user to nudge. They will receive an email with instructions on how to sign in.',
        '',
        'OK',
        () => history.push('/message-capping'),
      );
    } catch (err) {
      notification.alert(err.message, 'danger');
    }
  };





  useEffect(() => {
    const handleHubSpotAuth = async () => {
      const query = new URLSearchParams(location.search);
      const hubspotCode = query.get('code');
      const hubspotState = query.get('state');

      if (hubspotCode && hubspotState) {
        try {
          notification.alert('HubSpot authentication successful', 'success');

          const HubspotCallbackInput = {
            organizationId: organizationId,
            hubspotCode: hubspotCode,
            hubspotState: hubspotState
          };

          const Hubspotcallback = await UserService.VerifyOrgHubspotCallback(
            verifyHubspotCallback,
            HubspotCallbackInput
          );

          if (Hubspotcallback.ok) {
            setModalIsOpen(false);
            history.push('/message-capping'); // Redirects to /message-capping
          } else {
            setModalIsOpen(false);
            history.push('/message-capping'); // Redirects to /message-capping
          }
        } catch (error) {
          console.error("An error occurred during HubSpot validation with in larky", error);
          setModalIsOpen(false);
          history.push('/message-capping'); // Redirects to /message-capping
        }
      }
    };

    handleHubSpotAuth();
  }, [location.search, history, organizationId]);

  useEffect(() => {
    async function fetchLimit() {
      try {
        const messageCappingLimit = await OrganizationService.getMessageCappingLimit(getMessageCappingLimitQuery, organizationId);
        const profilePicture = await OrganizationService.getOrganizationProfilePicture(getOrganizationPictureQuery, organizationId);
        const newInitialValues = {
          organizationId: messageCappingLimit.organizationId,
          limitDay: messageCappingLimit.limitDay,
          limitWeek: messageCappingLimit.limitWeek,
          limitMonth: messageCappingLimit.limitMonth,
          updatedBy: messageCappingLimit.updatedBy,
          updatedOn: messageCappingLimit.updatedOn,
          profilePicture: profilePicture.url,
        };

        setInitialValues({ ...newInitialValues });
      } catch (err) {
        notification.alert(err.message, 'danger');
        Analytics.trackApplicationError(err.message, 'OrganizationSettings', 'useEffect');
      }
    }

    fetchLimit();
  }, [organizationId]);

  const getConduitTableData = async () => UserService.getConduitData(GetConduitDataQuery, organizationId);
  const getTableData = async (orderBy, sortOrder, start, params) => UserService.getOrganizationUserList(
    getUsersByOrg,
    start,
    organizationId,
    includeInactiveUsers,
  );

  const formik = useFormik({
    initialValues: {
      organizationId,
      limitDay: '',
      limitWeek: '',
      limitMonth: '',
      updatedBy: user.id,
      ...initialValues,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({
      organizationId,
      limitDay,
      limitWeek,
      limitMonth,
      updatedBy,
    }) => {
      try {
        const updatedOn = moment().format('YYYY-MM-DD hh:mm:ss');

        const payload = {
          organizationId,
          limitDay: Number(limitDay),
          limitWeek: Number(limitWeek),
          limitMonth: Number(limitMonth),
          updatedBy,
          updatedOn,
        };

        await OrganizationService.updateMessageCapping(updateMessageCappingMutation, organizationId, payload);
        notification.alert('Settings updated successfully', 'success');
      } catch (err) {
        notification.alert(err.message, 'danger');
      }
    },
  });

  const [uploadProfilePicture, setUploadProfilePicture] = useState(false);
  const [newProfilePicture, setNewProfilePicture] = useState(false);
  const [current, send] = useAuthMachineValue();
  const [userInfo, setUserInfo] = useState({
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    profilePicture: '',
    currentOrganization: {
      name: '',
    },
    organizations: [],
    role: '',
  });

  const [isAdmin, setIsAdmin] = useState(UserService.isAdmin());
  const [updateUserMutation] = useMutation(UPDATE_USER_MUTATION);
  const id = user.id || current.context.user.id;
  const getUserQuery = useQuery(GET_USER, { variables: { id }, skip: true });
  const currentUserInfo = getUser();

  useEffect(() => {
    async function getUserById() {
      const res = await UserService.index(getUserQuery, id);
      const userFetched = {
        ...res,
        organizations: res.organizations.map((org) => ({ ...org, id: org.id })),
      };
      const localUser = getUser();
      if (userFetched.id === localUser.id) {
        UserService.updateSessionStorageUser('role', userFetched.role);
        setIsAdmin(UserService.isAdmin());
      }
      let organization = localUser.currentOrganization;
      if (!userFetched.organizations
        .some((org) => org.id === localUser.currentOrganization.id)) {
        [organization] = userFetched.organizations;
        UserService.setCurrentOrganization(organization);
      }
      setUserInfo({ ...userFetched, currentOrganization: organization });
    }
    if (id && user.id == null) {
      getUserById();
    }
  }, [id, user.id, getUserQuery, initialValues.profilePicture]);

  const setProfilePicture = (profilePicture) => {
    if (current.context.user.id === id) {
      UserService.updateSessionStorageUser('orgProfilePicture', profilePicture);
      send('SET_USER', { user: { orgProfilePicture: profilePicture } });
    }
    initialValues.profilePicture = profilePicture;
  };

  return (
    <>
      {modalIsOpen && <HubSpotModal onClose={() => setModalIsOpen(false)} />}
      {uploadProfilePicture ? (
        <OrganizationProfilePicture
          user={{
            ...user,
            id,
          }}
          goBack={() => setUploadProfilePicture(false)}
          setProfilePicture={setProfilePicture}
          organization={{
            id: user.currentOrganization.id || '',
            profilePicture: initialValues.profilePicture || '',
          }}
        />
      ) : (
        <CustomCard
          title="Organization Settings"
          isForm
          isDarkBackground
          size="xxl"
          isLarge
        >
          <Container className={styles['xxl-container']}>
            <Row className={styles['org-settings-row']}>
              <Col>
                <Card className={styles['col-top']}>
                  <Card.Body>
                    <CustomField label="Organization">
                      {user.currentOrganization.name}
                    </CustomField>
                  </Card.Body>
                </Card>
                <Card className={styles['org-settings-card']}>
                  <Card.Body>
                    <Col
                      className={`${styles['profile-picture-container']} d-flex flex-column align-items-center justify-content-center`}
                      xs="12"
                      md="auto"
                      onClick={() => setUploadProfilePicture(true)}
                    >
                      <img
                        className={styles['profile-pic']}
                        src={initialValues.profilePicture || NoPhotoIcon}
                        alt="My account"
                      />
                      <Larky.Button onClick={() => setUploadProfilePicture(true)}>
                        Manage organization profile picture
                      </Larky.Button>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>

              <Col>
                <Card className={styles['org-settings-card-full']}>
                  <Card.Body>
                    <Form onSubmit={formik.handleSubmit}>
                      <h2>Configure Message Capping</h2>
                      <Row style={{ display: 'flex' }}>
                        <Col style={{ flex: 0.5 }} />
                        <div style={{ flex: 8 }}>
                          Setting reasonable limits on total nudges delivered to a single recipient is a
                          recommended best-practice to avoid over-messaging your audience and
                          creating a negative experience
                        </div>
                        <Col style={{ flex: 0.5 }} />
                      </Row>
                      <div style={{ margin: '2em 0 1em 0' }}>Use the controls here to configure your messaging limits</div>
                      <Row style={{ display: 'flex' }}>
                        <Col style={{ flex: 1 }} />
                        <Col style={{ flex: 1 }}>
                          <CustomInput
                            type="text"
                            name="limitDay"
                            value={formik.values.limitDay}
                            onChange={formik.handleChange}
                            error={formik.errors.limitDay}
                            size="sm"
                          />
                        </Col>
                        <Col style={{ marginTop: 10, marginLeft: 0, flex: 2 }}>
                          <MessageLabel title="Per Day" />
                        </Col>
                        <Col style={{ flex: 1 }} />
                      </Row>
                      <Row>
                        <Col style={{ flex: 1 }} />
                        <Col style={{ flex: 1 }}>
                          <CustomInput
                            type="text"
                            name="limitWeek"
                            value={formik.values.limitWeek}
                            onChange={formik.handleChange}
                            error={formik.errors.limitWeek}
                            size="sm"
                          />
                        </Col>
                        <Col style={{ marginTop: 10, marginLeft: 0, flex: 2 }}>
                          <MessageLabel title="Per Week" />
                        </Col>
                        <Col style={{ flex: 1 }} />
                      </Row>
                      <Row>
                        <Col style={{ flex: 1 }} />
                        <Col style={{ flex: 1 }}>
                          <CustomInput
                            type="text"
                            name="limitMonth"
                            value={formik.values.limitMonth}
                            onChange={formik.handleChange}
                            error={formik.errors.limitMonth}
                            size="sm"
                          />
                        </Col>
                        <Col style={{ marginTop: 10, flex: 2 }}>
                          <MessageLabel title="Per Month" />
                        </Col>
                        <Col style={{ flex: 1 }} />
                      </Row>
                      <Larky.Button
                        dataCy="submit"
                        type="submit"
                        onSubmit={formik.onSubmit}
                        disabled={formik.isSubmitting}
                        size="sm"
                      >
                        Save
                      </Larky.Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <CustomSection>
              <Col style={{ flex: 1 }}>
                <Row className="align-items-center justify-content-center mb-4">
                  <Col xs="12" md="auto">
                    <h2 className="text-center">Larky Conduit Data Connections</h2>
                  </Col>
                </Row>
                <div style={{
                      margin: '15px auto', // Center the div horizontally and add vertical margin
                      padding: '0 20px', // Add padding to prevent text from touching the edges
                      width: '90%', // Use percentage width for responsiveness
                      maxWidth: '800px', // Set a max-width for larger screens
                      boxSizing: 'border-box' ,// Ensure padding is included in the width
                      marginTop: '-20px'
                    }}>
                      Larky Conduit connects your data sources to the nudge platform to automate data flow
                      and make targeted messaging through dynamic segmentation fast and easy.
                      Contact <a href="mailto:support@larky.com">support</a> to learn more!
                    </div>


                {/*<h6 style={{ marginLeft: 220, color: 'black', width: '58%', marginTop:'-24px' }}>Larky Conduit connects your data sources to the nudge platform to automate data flow*/}
                {/*  and make targeted messaging through dynamic segmentation fast and easy.*/}
                {/*  Contact <a href="mailto:support@larky.com">support</a> to learn more!</h6>*/}
                <CustomTable
                  getData={getConduitTableData}
                  customRow={(data) => (
                    <DataSourceRow
                      data={data}
                      readOnly={userIsViewOnly}
                    />
                  )}
                  columns={['Conduit Source','Connection Status']}
                />
              </Col>
            </CustomSection>

            <br></br>

            <Form onSubmit={formik.handleSubmit}>
              <CustomSection>
                <Col style={{ flex: 1 }}>
                  <Row className="align-items-center justify-content-center mb-4">
                    <Col xs="12" md="auto">
                      <h2 className="text-center">User Management</h2>
                    </Col>
                  </Row>
                  <Row className={styles['row-container-align-right']}>
                    <CustomCheckbox
                      onChange={() => {
                        const reverseInactiveUsers = includeInactiveUsers ? 0 : 1;
                        setIncludeInactiveUsers(reverseInactiveUsers);
                      }}
                      value={!includeInactiveUsers}
                      title="Include Deactivated Users"
                      label="Include Deactivated Users"
                    />
                  </Row>
                  <CustomTable
                    getData={getTableData}
                    customRow={(data, handleSelect) => (
                      <UserRow
                        key={data.id}
                        data={data}
                        handleSelect={handleSelect}
                        currentUser={currentUserInfo}
                        handleInviteUser={() => inviteUserCallback(data, currentUserInfo, inviteUserMutation)}
                      />
                    )}
                    itemsPerPage={10}
                    columns={['Name', 'Email', 'Last Login', 'Admin User', 'Portal Role', 'Status']}
                    isPaginable
                    reloadDataToggle={includeInactiveUsers}
                  />
                  <Row className={styles['align-row-right']}>
                    <Larky.Button
                      onClick={() => {
                        history.push(ROUTES.USERS.INVITE);
                      }}
                    >
                      Create New User
                    </Larky.Button>
                  </Row>
                </Col>
              </CustomSection>

              <Form.Group as={Container} className="mt-4 mb-0">
                <Row className="justify-content-between" style={{ display: 'flex' }}>
                  <Col style={{ flex: 1 }} />
                  <Larky.Button
                    outlined
                    onClick={() => history.push(ROUTES.DASHBOARD.BASE)}
                  >
                    Close
                  </Larky.Button>
                  <Col style={{ flex: 1 }} />
                </Row>
              </Form.Group>
            </Form>
          </Container>
        </CustomCard>
      )}
    </>
  );
}

OrganizationSettings.propTypes = {
  organizationId: PropTypes.number,
  inviteSignup: PropTypes.bool,
};

OrganizationSettings.defaultProps = {
  organizationId: null,
  inviteSignup: false,
};

export default OrganizationSettings;
