// Dependencies
import React from 'react';

// Styles
import './App.scss';

// Pages
import MyAccount from 'pages/MyAccount/MyAccount.jsx';
import Users from 'pages/Users/Users';

import Login from 'pages/Login/Login';
import Nudges from 'pages/Nudges/Nudges';
import LocationNudges from 'pages/Nudges/LocationNudges';
import CreateOrUpdateNudge from 'pages/Nudges/components/CreateOrUpdateNudge/CreateOrUpdateNudge';
import CreateOrUpdateSegment from 'pages/Segments/components/CreateOrUpdateSegment/CreateOrUpdateSegment';
import Locations from 'pages/Locations/Locations';
import CreateOrUpdateLocation from 'pages/Locations/components/CreateOrUpdateLocation/CreateOrUpdateLocation';
import Home from 'pages/Home/Home';
import InviteUser from 'pages/OrganizationSettings/InviteUser/InviteUser';
import UpdatePassword from 'pages/UpdatePassword/UpdatePassword';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import Segments from 'pages/Segments/Segments';
import OrganizationSettings from 'pages/OrganizationSettings/OrganizationSettings';
import ManageUser from 'pages/OrganizationSettings/ManageUser';
import CreateOrUpdateCampaign from 'pages/Campaigns/components/CreateOrUpdateCampaign/CreateOrUpdateCampaign';
import Campaigns from 'pages/Campaigns/Campaigns';
import CampaignGallery from 'pages/Campaigns/components/CampaignGallery/CampaignGallery'
import PartnerManagement from "pages/PartnerManagement/PartnerManagement";
import ManageOrganization from "pages/PartnerManagement/components/ManageOrganization/ManageOrganization";
import CreateOrUpdateLocationNudge from "./pages/Nudges/components/CreateOrUpdateNudge/CreateOrUpdateLocationNudge";
import DownloadCsvRedirect from "./pages/Login/DownloadCsvRedirect";

const routes = [
  {
    path: '/',
    exact: true,
    menuItem: 'home',
    isPrivate: true,
    apiKeyCheck: true,
    children: <Home />,
  },
  {
    isPrivate: false,
    modal: true,
    path: '/login',
    children: <Login />,
  },
  {
    isPrivate: false,
    path: '/invite-signup',
    modal: true,
    children: <InviteUser inviteSignup />,
  },
  {
    isPrivate: false,
    modal: true,
    path: '/new-password',
    children: <UpdatePassword newPassword />,
  },
  {
    isPrivate: false,
    modal: true,
    path: '/forgot-password',
    children: <ForgotPassword />,
  },
  {
    isPrivate: true,
    path: '/users/invite',
    menuItem: 'account',
    modal: true,
    apiKeyCheck: true,
    children: <InviteUser />,
  },
  {
    isPrivate: true,
    path: '/message-capping',
    menuItem: 'account',
    modal: true,
    apiKeyCheck: true,
    children: <OrganizationSettings />,
  },
  {
    isPrivate: true,
    path: '/partner-management/main',
    menuItem: 'account',
    modal: true,
    apiKeyCheck: true,
    children: <PartnerManagement />,
  },
  {
    isPrivate: true,
    path: '/partner-management/edit/:organizationId',
    apiKeyCheck: true,
    modal: true,
    children: <ManageOrganization />,
  },
    {
    isPrivate: true,
    path: '/partner-management/create',
    apiKeyCheck: true,
    modal: true,
    children: <ManageOrganization />,
  },
  {
    isPrivate: true,
    path: '/update-password',
    menuItem: 'account',
    modal: true,
    children: <UpdatePassword />,
  },
  {
    isPrivate: true,
    path: '/nudges/create',
    exact: true,
    menuItem: 'nudges',
    apiKeyCheck: true,
    children: <CreateOrUpdateNudge />,
  },
  {
    isPrivate: true,
    menuItem: 'nudges',
    path: '/nudges/edit/:actionId',
    apiKeyCheck: true,
    children: <CreateOrUpdateNudge />,
  },
  {
    isPrivate: true,
    path: '/locationnudges/create',
    exact: true,
    menuItem: 'locationnudges',
    apiKeyCheck: true,
    children: <CreateOrUpdateLocationNudge />,
  },
  {
    isPrivate: true,
    menuItem: 'locationnudges',
    path: '/locationnudges/edit/:actionId',
    apiKeyCheck: true,
    children: <CreateOrUpdateLocationNudge />,
  },
  {
    isPrivate: true,
    path: '/segments/main',
    menuItem: 'segments',
    apiKeyCheck: true,
    children: <Segments />,
  },
  {
    isPrivate: true,
    path: '/segments/create',
    exact: true,
    menuItem: 'segments',
    apiKeyCheck: true,
    children: <CreateOrUpdateSegment />,
  },
  {
    isPrivate: true,
    menuItem: 'segments',
    path: '/segments/edit/:segmentId',
    apiKeyCheck: true,
    children: <CreateOrUpdateSegment />,
  },
  {
    isPrivate: true,
    path: '/locations/create',
    menuItem: 'locations',
    apiKeyCheck: true,
    children: <CreateOrUpdateLocation />,
  },
  {
    isPrivate: true,
    path: '/locations/edit/:id',
    menuItem: 'locations',
    apiKeyCheck: true,
    children: <CreateOrUpdateLocation />,
  },
  {
    isPrivate: true,
    path: '/locations',
    menuItem: 'locations',
    apiKeyCheck: true,
    children: <Locations />,
  },
  {
    isPrivate: true,
    path: '/nudges',
    menuItem: 'nudges',
    apiKeyCheck: true,
    children: <Nudges />,
  },
  {
    isPrivate: true,
    path: '/locationnudges',
    menuItem: 'locationnudges',
    apiKeyCheck: true,
    children: <LocationNudges />,
  },
  {
    isPrivate: true,
    modal: true,
    path: '/account',
    children: <MyAccount />,
  },
  {
    isPrivate: true,
    modal: true,
    path: '/users/edit/:userId',
    children: <ManageUser />,
  },
  {
    isAdmin: true,
    isPrivate: true,
    path: '/users',
    menuItem: 'users',
    children: <Users />,
  },
  {
    isPrivate: true,
    path: '/campaigns/create',
    menuItem: 'campaigns',
    apiKeyCheck: true,
    children: <CreateOrUpdateCampaign />,
  },
  {
    isPrivate: true,
    path: '/campaigns/edit/:campaignId',
    menuItem: 'campaigns',
    apiKeyCheck: true,
    children: <CreateOrUpdateCampaign />,
  },
  {
    isPrivate: true,
    path: '/campaigns/gallery',
    menuItem: 'campaigns',
    apiKeyCheck: true,
    children: <CampaignGallery />,
  },
  {
    isPrivate: true,
    path: '/campaigns',
    menuItem: 'campaigns',
    apiKeyCheck: true,
    children: <Campaigns />,
  },
  {
  isPrivate: true,
  path: "/download_csv",
  apiKeyCheck: true,
  children: <DownloadCsvRedirect />
  },

  // {
  //   path: '/about',
  //   children: <About />,
  // },
];

export default routes;
